import { useEffect, useState } from "react";
import { StatusTextProps } from "./types";
import { CountStatus } from "../../graphql";
import { inProgressPendingRegex, inProgressRegex } from "./utils";
import { Transition } from "@headlessui/react";
import { FaExclamationTriangle } from "react-icons/fa";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { MdOutlineContentCopy } from "react-icons/md";
import { toast } from "react-toastify";
import "./styles.css";

export const StatusText = ({ count, retryFunction }: StatusTextProps) => {
  const [showErrorDetails, setShowErrorDetails] = useState(false);

  const toggleErrorDetails = () => {
    setShowErrorDetails(!showErrorDetails);
  };

  const [maskError, setMaskError] = useState(false);
  const [maskedStatus, setMaskedStatus] = useState<string>();

  useEffect(() => {
    // Only mask errors for Mincron counts (Eclipse counts are 4 digits long)
    const isMincron = count.branchId.length !== 4;

    if (count.status === CountStatus.Error && isMincron && count.errorMessage) {
      // Check if the count is "IN PROGRESS"
      const isInProgress = inProgressRegex.test(count.errorMessage);
      // Check if the count is "IN PROGRESS - PENDING"
      const isPending = inProgressPendingRegex.test(count.errorMessage);
      // Mask the input if it is in progress or pending
      setMaskError(isInProgress || isPending);
      // Set the masked status if appropriate
      setMaskedStatus(
        isInProgress ? "IN PROGRESS" : isPending ? "IN PROGRESS - PENDING" : count.status
      );
    }
  }, [count]);

  const copyToClipboard = (errorMessage: any) => {
    navigator.clipboard.writeText(errorMessage);
    toast.info("Error copied to clipboard!", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const classifyError = (error: string) => {
    if (error.includes("KGW-500")) {
      return "Count has no products.";
    } else if (error.includes("unexpected end of stream")) {
      return "Connection error.";
    } else {
      return "An error occurred.";
    }
  };

  return maskError ? (
    <p className='text-gray-900 whitespace-no-wrap'>{maskedStatus}</p>
  ) : (
    <div>
      {count.errorMessage && count.status === CountStatus.Error ? (
        <div>
          <div className='flex gap-1 lg:p-1'>
            <div className='flex-none'>
              <div className='lg:p-2'>
                <FaExclamationTriangle size={24} color='red' />
              </div>
            </div>
            <div className='flex-none'>
              <p className='text-red-600 font-semibold whitespace-no-wrap'>Count load failed:</p>
              <p className='text-red-600 whitespace-no-wrap'>{classifyError(count.errorMessage)}</p>
            </div>
            <div className='flex-none'>
              {classifyError(count.errorMessage) === "Connection error." ? (
                <p
                  className='text-red-600 font-bold pl-0 lg:p-3 underline btn-retry'
                  onClick={() => retryFunction(count)}
                >
                  Retry
                </p>
              ) : (
                <a
                  className='text-red-600 font-bold pl-0 lg:p-3 underline btn-report'
                  href="https://morsco.service-now.com/sp?id=sc_cat_item&sys_id=3f1dd0320a0a0b99000a53f7604a2ef9"
                  target="_blank"
                  rel="noreferrer"
                >
                  Report issue
                </a>
              )}
            </div>
          </div>

          <div className='flex gap-1 lg:p-2'>
            <div className='flex-grow'>
              <p
                className='text-gray-700 font-semibold min-w-full break-all cursor-pointer '
                onClick={toggleErrorDetails}
              >
                {showErrorDetails ? (
                  <div className='flex gap-1'>
                    <div className='flex-none'>
                      <IoIosArrowUp size={16} />
                    </div>
                    <div className='flex-grow'>Hide Details</div>
                  </div>
                ) : (
                  <div className='flex gap-1'>
                    <div className='flex-none'>
                      <IoIosArrowDown size={16} />
                    </div>
                    <div className='flex-grow'>Show Details</div>
                  </div>
                )}
              </p>
            </div>
            <div className='flex-grow'>
              <p
                className='text-gray-700 font-semibold min-w-full break-all cursor-pointer'
                onClick={() => copyToClipboard(count.errorMessage)}
              >
                <div className='flex gap-1'>
                  <div className='flex-none'>
                    <MdOutlineContentCopy size={16} />
                  </div>
                  <div className='flex-grow'>Copy Error</div>
                </div>
              </p>
            </div>
          </div>

          <Transition
            show={showErrorDetails}
            enter='transition ease-out duration-200'
            enterFrom='opacity-0 max-h-0'
            enterTo='opacity-100 max-h-full'
            leave='transition ease-in duration-150'
            leaveFrom='opacity-100 max-h-full'
            leaveTo='opacity-0 max-h-0'
          >
            <br />
            <p className='text-red-600 font-semibold min-w-full break-all'>{count.errorMessage}</p>
          </Transition>
        </div>
      ) : (
        <p className='text-gray-900 whitespace-no-wrap'>
          {count.status === "COMPLETE" ? "LOADED" : count.status}
        </p>
      )}
    </div>
  );
};
